import gql from 'graphql-tag';

export const upsertInvestorAccount = gql`
  mutation upsertInvestorAccount($accountId: String $accountAttributes: AccountInputType! $accountType: InvestorAccountTypeEnum!) {
    upsertInvestorAccount(
      accountId: $accountId
      accountAttributes: $accountAttributes
      accountType: $accountType
    ) {
      accountId
      linkedBank {
        accountNumber
        routingNumber
        bankName
        accountType
        plaidAccountId
      }
      accountType
    }
  }`;

export const submitInvestorAccount = gql`
  mutation submitInvestorAccount($userId: String, $accountId: String!, $accountType: InvestorAccountTypeEnum!){
    submitInvestorAccount(
      userId: $userId,
      accountId: $accountId,
      accountType: $accountType
    )
  }`;

export const updateInvestorProfileData = gql`
  mutation updateInvestorProfileData($isPartialProfile: Boolean! $employment: EmploymentStatusInput $brokerageFirmName: String $publicCompanyTicker: String $netWorth: Int $annualIncome: [InvestorAnnualIncome] $experienceLevel: InvestorExperienceLevelTypeEnum $isRiskTaker: Boolean $isComfortable: Boolean $taxFilingAs: InvestorProfileTypeEnum) {
    createInvestorProfile(
      employmentStatusInfo: $employment
      brokerageFirmName: $brokerageFirmName
      publicCompanyTicker: $publicCompanyTicker
      netWorth: $netWorth
      annualIncome: $annualIncome
      experienceLevel: $experienceLevel
      isRiskTaker: $isRiskTaker
      isComfortable: $isComfortable
      taxFilingAs: $taxFilingAs
      isPartialProfile: $isPartialProfile
    ) {
      id
      status
    }
  }`;

export const isUniqueTaxId = gql`
query isUniqueTaxId($taxId: String!) {
  isUniqueTaxId(taxId: $taxId) {
    alreadyExists
  }
}`;

export const crowdPayAccountNotifyGs = gql`
  mutation crowdPayAccountNotifyGS($userId: String!, $accountId: String!) {
    crowdPayAccountNotifyGS(
      userId: $userId
      accountId: $accountId
    )
  }`;
export const createIndividualGoldStarInvestor = gql`
  mutation createIndividualGoldStarInvestor($userId: String, $accountId: String!) {
    createIndividualGoldStarInvestor(
      userId: $userId
      accountId: $accountId
    )
  }
`;

export const adminGetUserClosedAccounts = gql`
query adminGetUserClosedAccounts($userId: String!) {
  adminGetUserClosedAccounts(
    userId: $userId
  ){
    userId
    accountId
    name
    accountType
    accountStatus
    closed{
      date
      reason
      by
    }
    taxStatement{
      fileId
      fileName
      year
      formType
    }
    created{
      date
    }
    accountStatus
    linkedBank{
      bankName
      accountNumber
    }
    initialDepositAmount
    goldstar{
      contactId investorKey 
      accountId
    }
  }
}`;

export const adminCloseInvestorAccount = gql`
mutation adminCloseInvestorAccount($userId: String!, $accountId: String!, $accountType: InvestorAccountTypeEnum!, $reason: String) {
  adminCloseInvestorAccount (
    userId: $userId
    accountId: $accountId
    accountType: $accountType
    reason: $reason
  )
  {
   errorMessage
   status
 }
}`;

export const updateToAccountProcessing = gql`
mutation updateToAccountProcessing ($accountId: String!, $error: String!){
  updateToAccountProcessing (
    accountId: $accountId
    error: $error
  )
}`;
