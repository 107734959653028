/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ReactCodeInput from 'react-code-input';
import { Modal, Header, Form, Button, Message } from 'semantic-ui-react';
import { FormInput, FormPasswordStrength } from '../../../theme/form';
import Helper from '../../../helper/utility';
import { authActions } from '../../../services/actions';
import { ListErrors } from '../../../theme/shared';

const isMobile = document.documentElement.clientWidth < 768;

@inject('authStore', 'uiStore')
@observer
export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    const { FORGOT_PASS_FRM, RESET_PASS_FRM } = this.props.authStore;
    RESET_PASS_FRM.fields.email.value = FORGOT_PASS_FRM.fields.email.value;
  }

  componentDidMount() {
    Helper.otpShield();
  }

  componentWillUnmount() {
    this.props.authStore.resetForm('RESET_PASS_FRM');
    this.props.uiStore.clearErrors();
  }

  onSubmit = (e) => {
    e.preventDefault();
    authActions.setNewPassword().then(() => {
      if (this.props.uiStore.isFromBusinessApplication) {
        this.props.history.push(this.props.uiStore.authRef);
      } else {
        this.props.history.push('/login');
      }
    }).catch(err => window.logger(err));
  }

  handleCloseModal = (e) => {
    e.stopPropagation();
    this.props.history.push(this.props.uiStore.authRef || '/');
  }

  render() {
    const {
      RESET_PASS_FRM,
      resetPassChange,
      pwdInputType,
      currentScore,
    } = this.props.authStore;
    const { errors } = this.props.uiStore;
    return (
      <Modal open closeIcon onClose={this.handleCloseModal} size="mini" closeOnDimmerClick={false}>
        <Modal.Header className="center-align signup-header">
          <Header as="h3">Password Reset</Header>
          <p>If an account is associated with this email address, an email has been sent with a 6-digit verification code. Please enter your verification code here to update your password.</p>
        </Modal.Header>
        <Modal.Content className="signup-content">
          <Form error onSubmit={this.onSubmit}>
            <Form.Field className="otp-wrap center-align mt-10">
              <label>Enter verification code here:</label>
              <ReactCodeInput
                fields={6}
                type="number"
                filterChars
                name="code"
                autoFocus={!isMobile}
                className="otp-field mt-10"
                pattern="[0-9]*"
                inputmode="numeric"
                fielddata={RESET_PASS_FRM.fields.code}
                onChange={resetPassChange}
              />
            </Form.Field>
            <FormPasswordStrength
              key="password"
              name="password"
              type="password"
              iconDisplay
              minLength={8}
              minScore={4}
              tooShortWord="Weak"
              scoreWords={['Weak', 'Okay', 'Good', 'Strong', 'Stronger']}
              inputProps={{ name: 'password', autoComplete: 'off', placeholder: 'Password' }}
              changed={resetPassChange}
              fielddata={RESET_PASS_FRM.fields.password}
            />
            <FormInput
              key="verify"
              type={pwdInputType}
              name="verify"
              fielddata={RESET_PASS_FRM.fields.verify}
              changed={resetPassChange}
            />
            {errors
              && (
<Message error textAlign="left" className="mt-30">
                <ListErrors errors={errors.message ? [errors.message] : [errors]} />
              </Message>
              )
            }
            <div className="mt-30 center-align">
              <Button primary size="large" className="very relaxed" content="Set new password" loading={this.props.uiStore.inProgress} disabled={!RESET_PASS_FRM.meta.isValid || !currentScore} />
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
