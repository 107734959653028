
import { observable, action, toJS, computed } from 'mobx';
import { get } from 'lodash';
import graphql from 'mobx-apollo';
import { updateOfferingRepaymentsMeta, getListOfPartialOrCIPProcessingAccount, adminProcessFullInvestorAccount, adminCrowdPayProcessCip, adminProcessInvestorAccount, adminEncryptOrDecryptValue, adminProcessTransferRequest } from '../../queries/data';
import { GqlClient as client } from '../../../../api/gqlApi';
import Helper from '../../../../helper/utility';
import { FormValidator as Validator } from '../../../../helper';
import { PROCESS_TRANSFER_REQ_META, OFFERING_REPAYMENT_META, PROCESS_FULL_ACCOUNT_META, RECREATEGOLDSTAR_META, ENCRYPTDECRYPTUTILITY_META } from '../../../constants/admin/data';

export class DataStore {
  @observable OFFERING_REPAYMENT_META_FRM = Validator.prepareFormObject(OFFERING_REPAYMENT_META);

  @observable PROCESS_FULL_ACCOUNT_META_FRM =
    Validator.prepareFormObject(PROCESS_FULL_ACCOUNT_META);

  @observable RECREATEGOLDSTAR_FRM =
    Validator.prepareFormObject(RECREATEGOLDSTAR_META);

  @observable ENCRYPTDECRYPTUTILITY_FRM =
    Validator.prepareFormObject(ENCRYPTDECRYPTUTILITY_META);


  @observable PROCESS_TRANSFER_REQ_FRM =
    Validator.prepareFormObject(PROCESS_TRANSFER_REQ_META);

  @observable inProgress = {
    offeringRepayment: false,
    processFullAccount: false,
    adminProcessCip: false,
    encryptOrDecryptValue: false,
    processTransferRequest: false,
  };

  @observable partialOrCipAccountData = {};

  @observable outputMsg = null;

  @action
  setFieldValue = (field, value, field2 = false) => {
    if (field2) {
      this[field][field2] = value;
    } else {
      this[field] = value;
    }
  }

  @action
  resetForm = (form) => {
    this[form] = Validator.resetFormData(this[form]);
  }

  @action
  resetOfferingAudit = () => {
    this.OFFERING_REPAYMENT_META_FRM = Validator.prepareFormObject(OFFERING_REPAYMENT_META);
    this.outputMsg = null;
  }

  @action
  formChange = (e, res, form) => {
    this[form] = Validator.onChange(this[form], Validator.pullValues(e, res));
  };

  @action
  formDataChange = (e, res, form, fieldType) => {
    if (fieldType === 'mask') {
      this[form] = Validator.onChange(
        this[form],
        { name: res, value: e.floatValue },
      );
      this.setFieldValue('countValues', '');
    }
  };

  @action
  updateOfferingRepaymentsMeta = () => {
    const offeringData = Validator.evaluateFormData(this.OFFERING_REPAYMENT_META_FRM.fields);
    this.setFieldValue('inProgress', true, 'offeringRepayment');
    this.setFieldValue('outputMsg', null);
    return new Promise((res, rej) => {
      client
        .mutate({
          mutation: updateOfferingRepaymentsMeta,
          variables: {
            audit: Boolean(toJS(offeringData.audit).length),
            offeringId: offeringData.offeringId,
          },
        })
        .then(action((result) => {
          this.setFieldValue('inProgress', false, 'offeringRepayment');
          Helper.toast('Your request is processed.', 'success');
          this.resetForm('OFFERING_REPAYMENT_META_FRM');
          this.setFieldValue('outputMsg', { type: 'success', data: get(result, 'data.updateOfferingRepaymentsMeta') });
          res(result);
        }))
        .catch((error) => {
          this.setFieldValue('outputMsg', { type: 'error', data: get(error, 'message') });
          this.setFieldValue('inProgress', false, 'offeringRepayment');
          Helper.toast('Something went wrong, please try again later.', 'error');
          rej(error);
        });
    });
  }

  @action
  processFullInvestorAccountMeta = () => {
    const processData = Validator.evaluateFormData(this.PROCESS_FULL_ACCOUNT_META_FRM.fields);
    this.setFieldValue('inProgress', true, 'processFullAccount');
    this.setFieldValue('outputMsg', null);
    return new Promise((res, rej) => {
      client
        .mutate({
          mutation: adminProcessFullInvestorAccount,
          variables: {
            userId: processData.userId,
            accountId: processData.accountId,
            createRSAccount: (toJS(processData.options)).includes('createRSAccount'),
            createInitialDeposit: (toJS(processData.options)).includes('createInitialDeposit'),
            sendEmailToInvestor: (toJS(processData.options)).includes('sendEmailToInvestor'),
            createGsContactAccount: (toJS(processData.options)).includes('createGsContactAccount'),
            createAccountPdf: (toJS(processData.options)).includes('createAccountPdf'),
            sendCrowdPayEmailToGS: (toJS(processData.options)).includes('sendCrowdPayEmailToGS'),
            skipFullAccountValidation: (toJS(processData.options)).includes('skipFullAccountValidation'),
          },
        })
        .then(action((result) => {
          this.setFieldValue('inProgress', false, 'processFullAccount');
          Helper.toast('Your request is processed.', 'success');
          this.resetForm('PROCESS_FULL_ACCOUNT_META_FRM');
          res(result);
        }))
        .catch((error) => {
          this.setFieldValue('inProgress', false, 'processFullAccount');
          Helper.toast(get(error, 'message'), 'error');
          rej(error);
        });
    });
  }

  @action
  adminProcessInvestorAccount = processData => new Promise((res, rej) => {
    client
      .mutate({
        mutation: adminProcessInvestorAccount,
        variables: {
          userId: processData.userId,
          accountId: processData.accountId,
        },
      })
      .then(() => {
        res();
      })
      .catch((error) => {
        rej(error);
      });
  });

  @action
  processTransferRequest = () => {
    const transferId = this.PROCESS_TRANSFER_REQ_FRM.fields.transferId.value;
    this.setFieldValue('inProgress', true, 'processTransferRequest');
    client
      .mutate({
        mutation: adminProcessTransferRequest,
        variables: { transferId },
      })
      .then(() => {
        this.setFieldValue('inProgress', false, 'processTransferRequest');
      })
      .catch((error) => {
        Helper.toast(get(error, 'message'), 'error');
        this.setFieldValue('inProgress', false, 'processTransferRequest');
      });
  };

  @action
  adminProcessCip = () => {
    const processData = Validator.evaluateFormData(this.RECREATEGOLDSTAR_FRM.fields);
    this.setFieldValue('inProgress', true, 'adminProcessCip');
    this.setFieldValue('outputMsg', null);
    return new Promise((res, rej) => {
      client
        .mutate({
          mutation: adminCrowdPayProcessCip,
          variables: {
            userId: processData.userId,
            accountId: processData.accountId,
          },
        })
        .then(action((result) => {
          if (result.data.adminCrowdPayProcessCip) {
            this.adminProcessInvestorAccount(processData).then(() => {
              this.setFieldValue('inProgress', false, 'adminProcessCip');
              Helper.toast('Your request is processed.', 'success');
              this.resetForm('RECREATEGOLDSTAR_FRM');
              res(result);
            }).catch((error) => {
              this.setFieldValue('inProgress', false, 'adminProcessCip');
              Helper.toast(get(error, 'message'), 'error');
              rej(error);
            });
          }
        }))
        .catch((error) => {
          this.setFieldValue('inProgress', false, 'adminProcessCip');
          Helper.toast(get(error, 'message'), 'error');
          rej(error);
        });
    });
  }

  @action
  encryptOrDecryptValue = (type) => {
    const processData = Validator.evaluateFormData(this.ENCRYPTDECRYPTUTILITY_FRM.fields);
    processData.type = type;
    this.setFieldValue('inProgress', type, 'encryptOrDecryptValue');
    this.setFieldValue('outputMsg', null);
    return new Promise((resolve, reject) => {
      this.data = graphql({
        client,
        query: adminEncryptOrDecryptValue,
        variables: processData,
        fetchPolicy: 'network-only',
        onFetch: (res) => {
          if (res && res.adminEncryptOrDecryptValue && !this.data.loading) {
            this.setFieldValue('inProgress', false, 'encryptOrDecryptValue');
            Helper.toast('Your request is processed.', 'success');
            resolve(res.adminEncryptOrDecryptValue);
          }
        },
        onError: () => {
          this.setFieldValue('inProgress', false, 'encryptOrDecryptValue');
          Helper.toast('Something went wrong, please try again later.', 'error');
          // }
          reject();
        },
      });
    });
  }

  @action
  getListOfPartialOrCIPProcessingAccount = () => {
    this.partialOrCipAccountData = graphql({
      client,
      query: getListOfPartialOrCIPProcessingAccount,
      fetchPolicy: 'network-only',
    });
  }

  @computed get partialOrCipAccountList() {
    return get(this.partialOrCipAccountData, 'data.getListOfPartialOrCIPProcessingAccount') || [];
  }
}


export default new DataStore();
